import { Col, Image, Layout, message, Row, Space, Tag, Typography } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import OptionIcon from "../../../../assets/icons/collection-option.svg";
import VideoIcon from "../../../../assets/icons/jouer.svg";
import LockIcon from "../../../../assets/icons/lock-outlined-without-point.svg";
import ArrowLeftIconComponent from "../../../../components/ArrowLeftIconComponent";
import CollectionDeleteModalComponent from "../../../../components/Collections/Modals/CollectionDeleteModalComponent";
import CollectionDetailModalComponent from "../../../../components/Collections/Modals/CollectionDetailModalComponent";
import ShareModalComponent from "../../../../components/ShareModalComponent";
import SpinnerComponent from "../../../../components/SpinnerComponent/SpinnerComponent";
import { ICreatorCollection } from "../../../../helpers/types";
import { deleteCollectionById } from "../../../../redux/collections/collectionsSlice";
import { AppDispatch } from "../../../../redux/store";
import { collectionService } from "../../../../services/collection.service";
import PullToRefresh from "react-simple-pull-to-refresh";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { DEFAULT_COLLECTION_PAGE_REPORT, FALLBACK_IMAGE } from "../../../../helpers/constant";
import GrowthIcon from "../../../../assets/icons/growth.svg";
import DecreasingIcon from "../../../../assets/icons/decreasing.svg";
import "./index.less";
import VideoPlayer from "../../../../components/VideoPlayerComponent";

const CreatorDetailCollectionPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { collectionId, username } = useParams();
  const [collectionData, setCollectionData] = useState<ICreatorCollection | null>(null);
  const [openOptionModal, setOpenOptionModal] = useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(-1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list, setList] = useState<Array<any>>([]);
  const itemRef: any = useRef(null);

  const collectionLink = `${window.location.origin}/common/collection/${collectionId}/${username}`;

  useEffect(() => {
    if (collectionId) {
      setLoading(true);
      collectionService
        .getCreatorCollectionDetail(collectionId)
        .then((data: ICreatorCollection) => {
          setCollectionData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("error:", error);
          setLoading(false);
        });

      getEarningList(collectionId, 1);
    }
  }, [collectionId]);

  const getEarningList = (collectionId: any, page: any) => {
    collectionService
      .getCreatorEarningReport(collectionId, page)
      .then((data: any) => {
        setList(data.data);
        setTotalPages(Math.ceil(data.total / DEFAULT_COLLECTION_PAGE_REPORT));
      })
      .catch((error) => {
        console.error("error:", error);
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [collectionId]);

  const openModal = (value: string) => {
    switch (value) {
      case "options":
        setOpenOptionModal(true);
        break;
      case "share":
        setOpenOptionModal(false);
        setOpenShareModal(true);
        break;
      case "delete":
        setOpenOptionModal(false);
        setOpenDeleteModal(true);
        break;
      default:
        break;
    }
  };

  const closeModal = (value: string) => {
    switch (value) {
      case "options":
        setOpenOptionModal(false);
        break;
      case "share":
        setOpenShareModal(false);
        break;
      case "delete":
        setOpenDeleteModal(false);
        break;
      default:
        break;
    }
  };

  const deleteCollection = async () => {
    if (collectionId) {
      try {
        setLoading(true);
        const resultAction = await dispatch(deleteCollectionById(collectionId));
        if (deleteCollectionById.fulfilled.match(resultAction)) {
          message.success(t("collection-successfully-deleted"));
          setOpenDeleteModal(false);
          navigate("/creator/profile");
        } else {
          console.error("Error deleting collection:", resultAction.payload);
        }
      } catch (error) {
        console.error("Error deleting collection:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getMonthLabel = (monthShort: string): string => {
    let monthLabel = "";
    switch (monthShort) {
      case "Jan":
        monthLabel = t("january-label");
        break;
      case "Feb":
        monthLabel = t("february-label");
        break;
      case "Mar":
        monthLabel = t("march-label");
        break;
      case "Apr":
        monthLabel = t("april-label");
        break;
      case "May":
        monthLabel = t("may-label");
        break;
      case "Jun":
        monthLabel = t("june-label");
        break;
      case "Jul":
        monthLabel = t("july-label");
        break;
      case "Aug":
        monthLabel = t("august-label");
        break;
      case "Sep":
        monthLabel = t("september-label");
        break;
      case "Oct":
        monthLabel = t("october-label");
        break;
      case "Nov":
        monthLabel = t("november-label");
        break;
      case "Dec":
        monthLabel = t("december-label");
        break;
      default:
        break;
    }
    return monthLabel;
  };

  const loadMoreFeeds = () => {
    if (hasNextPage && collectionId) {
      collectionService
        .getCreatorEarningReport(collectionId, currentPage + 1)
        .then((data: any) => {
          setCurrentPage(currentPage + 1);
          setList((list) => [...list, ...data.data]);
          setTotalPages(Math.ceil(data.total / DEFAULT_COLLECTION_PAGE_REPORT));
        })
        .catch((error) => {
          console.error("error:", error);
          setLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMoreFeeds,
  });

  const handleRefresh = async () => {
    getEarningList(collectionId, 1);
    setCurrentPage(1);
    setHasNextPage(false);
  };

  const handleVideoIconClicked = () => {
    itemRef.current?.children?.[0]?.click();
  };

  useEffect(() => {
    if (list.length !== 0 && totalPages !== -1) {
      setHasNextPage(
        Math.ceil(list.length / DEFAULT_COLLECTION_PAGE_REPORT) < totalPages || Math.ceil(list.length / DEFAULT_COLLECTION_PAGE_REPORT) !== totalPages
      );
    }
  }, [list, totalPages]);

  return (
    <Layout className="gradient-background-profile">
      <Layout.Content className="content creator-detail-collection-page-wrapper background-transparent">
        <Row className="collection-header">
          <ArrowLeftIconComponent top={5} left={11} />
          <div className="title-container">
            <Typography.Text className="header-title-black-28">{t("details")}</Typography.Text>
          </div>
          <div className="right-icon">
            <img src={OptionIcon} width={24} onClick={() => openModal("options")} />
          </div>
        </Row>
        {loading ? (
          <Row className="w-full justify-content-center mt-20">
            <SpinnerComponent />
          </Row>
        ) : (
          <>
            {/* Files row */}
            <Row className="files-row">
              {collectionData?.files?.map((file) => (
                <Col key={file.url} className="file-item">
                  <div className="file-wrapper">
                    {file.type.includes("video") && <img className="play-icon-overlay" src={VideoIcon} onClick={handleVideoIconClicked} />}
                    {file.isPrivate && <img className="private-icon-overlay" src={LockIcon} />}
                    <span ref={itemRef}>
                      <Image
                        loading="lazy"
                        src={file?.type.includes("video") ? file.thumbnailUrl : file?.url}
                        fallback={FALLBACK_IMAGE}
                        className="file-image"
                        preview={
                          file?.type.includes("video")
                            ? {
                                destroyOnClose: true,
                                modalRender: () => (
                                  <div className="video-preview-wrapper">
                                    <VideoPlayer videoUrl={file.url!} thumbnailUrl={""} autoPlay={true} />
                                  </div>
                                ),
                              }
                            : true
                        }
                      />
                    </span>
                    {/* <img src={file?.type.includes("video") ? file.thumbnailUrl : file?.url} alt={file.type} className="file-image" /> */}
                  </div>
                </Col>
              ))}
            </Row>
            <div className="collection-resume-padding">
              {/* Price, revenue and sales row */}
              <Row className="mt-20 container stats-container">
                <Col className="collection-stats-col">
                  <Typography.Text className="principal-text">{`${collectionData?.price.toFixed(2) || "0.00"} €`}</Typography.Text>
                  <Typography.Text className="subtitle-text">{t("price-per-unit")}</Typography.Text>
                </Col>
                <Col className="collection-stats-col">
                  <Typography.Text className="principal-text">{`${collectionData?.totalRevenue.toFixed(2) || "0.00"} €`}</Typography.Text>
                  <Typography.Text className="subtitle-text">{t("income-generated")}</Typography.Text>
                </Col>
                <Col className="collection-stats-col">
                  <Typography.Text className="principal-text">{collectionData?.totalPurchases || 0}</Typography.Text>
                  <Typography.Text className="subtitle-text">{t("total-unlock")}</Typography.Text>
                </Col>
              </Row>
              {/* Title and description row */}
              <Row className="mt-20 container info-container">
                <Col className="collection-info-col">
                  <Typography.Text className="title-text">{collectionData?.title}</Typography.Text>
                  <Typography.Text className="mt-7 description-text">{collectionData?.description}</Typography.Text>
                </Col>
              </Row>
            </div>

            {list.length !== 0 && (
              <div className="gap-10 list-row">
                <Row className="container">
                  <Row className="daily-report-container">
                    <Typography.Text className="daily-report-text">{t("daily-report")}</Typography.Text>
                  </Row>
                  <PullToRefresh
                    isPullable={true}
                    onRefresh={handleRefresh}
                    resistance={4}
                    pullDownThreshold={90}
                    pullingContent={
                      <Row className="mt-20 mb-20 d-flex justify-content-center">
                        <div className="beat-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </Row>
                    }
                  >
                    <>
                      {list?.map(
                        (
                          revenue: {
                            total_cost_month: number;
                            stats: string;
                            day: number;
                            month: string;
                          },
                          index: number
                        ) => {
                          return (
                            <Row className="revenue-row" key={index}>
                              <div className="icon-date-increase-container">
                                <img
                                  src={revenue.stats.includes("+") ? GrowthIcon : DecreasingIcon}
                                  className="increase-icon collection"
                                  alt="increased"
                                />
                                <div className="date-increase-container">
                                  <Typography.Text className="font-16-medium text-white-color">
                                    {revenue.day + " "}
                                    {getMonthLabel(revenue.month)}
                                  </Typography.Text>
                                  <Typography.Text className="daily-monthly-revenue-text">
                                    <span className={revenue.stats.includes("+") ? "increased" : "decreased"}>{revenue.stats}</span> de croissance
                                  </Typography.Text>
                                </div>
                              </div>

                              <Typography.Text className="font-18-bold text-white-color">
                                {revenue.total_cost_month.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                €
                              </Typography.Text>
                            </Row>
                          );
                        }
                      )}
                    </>
                  </PullToRefresh>
                  {(loading || hasNextPage) && (
                    <Row className="next-page-loading">
                      <SpinnerComponent refProp={sentryRef} />
                    </Row>
                  )}
                </Row>
              </div>
            )}

            <CollectionDetailModalComponent
              isOpen={openOptionModal}
              link={collectionLink}
              onClose={() => closeModal("options")}
              editCollection={() => navigate(`/creator/edit/collection/${collectionId}/${collectionData?.title}/${collectionData?.price}`)}
              openShareModal={() => openModal("share")}
              openDeleteModal={() => openModal("delete")}
            />
            <CollectionDeleteModalComponent isOpen={openDeleteModal} onClose={() => closeModal("delete")} deleteCollection={deleteCollection} />
            <ShareModalComponent isOpen={openShareModal} onClose={() => closeModal("share")} link={collectionLink} />
          </>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default CreatorDetailCollectionPage;
