import { Layout, message, Spin, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HeaderTitlePage from "../../../../components/HeaderTitlePage";
import SpinnerComponent from "../../../../components/SpinnerComponent/SpinnerComponent";
import HistoryPresentationVideoComponent from "../../../../components/discover/creator/HistoryPresentationVideoComponent";
import PresentationVideoChartComponent from "../../../../components/discover/creator/PresentationVideoChartComponent";
import UploadPresentationVideoComponent from "../../../../components/discover/creator/UploadPresentationVideoComponent";
import DeletePresentationVideoModalComponent from "../../../../components/discover/creator/modals/DeletePresentationVideoModalComponent";
import UploadVideoModalComponent from "../../../../components/discover/creator/modals/UploadVideoModalComponent";
import VideoBeingVerifiedModalComponent from "../../../../components/discover/creator/modals/VideoBeingVerifiedModalComponent";
import VideoExhaustedModalComponent from "../../../../components/discover/creator/modals/VideoExhaustedModalComponent";
import VideoRejectedModalComponent from "../../../../components/discover/creator/modals/VideoRejectedModalComponent";
import WhatKindVideoMakeModalComponent from "../../../../components/discover/creator/modals/WhatKindVideoMakeModalComponent";
import { DISCOVER_APPROVED, DISCOVER_EXPIRED, DISCOVER_PENDING, DISCOVER_REJECTED } from "../../../../helpers/constant";
import { IDiscoverDto } from "../../../../helpers/types";
import { deleteDiscoversById, getCreatorDiscovers } from "../../../../redux/discovers/discoversSlice";
import { AppDispatch, StoreState } from "../../../../redux/store";
import { userHasExpiredDiscover } from "../../../../redux/users/usersSlice";
import "./index.less";

const CreatePresentationVideoPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const shouldFetch = useRef(true);
  const { creatorDiscovers, discoversHistory, loadingCreatorDiscovers, errorCreatorDiscovers } = useSelector((state: StoreState) => state.discovers);
  const [selectedDiscover, setSelectedDiscover] = useState<IDiscoverDto | null>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  // MODALS ATTRIBUTE
  const [whatVideoMakeModalIsVisible, setWhatVideoMakeModalIsVisible] = useState<boolean>(false);
  const [videoBeingVerifiedModalIsVisible, setVideoBeingVerifiedModalIsVisible] = useState<boolean>(false);
  const [videoRejectedModalIsVisible, setVideoRejectedModalIsVisible] = useState<boolean>(false);
  const [videoExhaustedModalIsVisible, setVideoExhaustedModalIsVisible] = useState<boolean>(false);
  const [uploadVideoModalIsVisible, setUploadVideoModalIsVisible] = useState<boolean>(false);
  const [deleteVideoModalIsVisible, setDeleteVideoModalIsVisible] = useState<boolean>(false);
  const [toDeleteDiscoverId, setToDeleteDiscoverId] = useState<string | null>(null);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(getCreatorDiscovers());
    }
  }, []);

  useEffect(() => {
    if (errorCreatorDiscovers) {
      console.warn("error.message -=-=-=-=-=-=-", errorCreatorDiscovers);
      message.error(errorCreatorDiscovers);
    }
  }, [errorCreatorDiscovers]);

  const handleSelectDiscover = (discover: IDiscoverDto | null) => {
    if (discover === null) {
      setSelectedDiscover(null);
      return;
    }
    if (discover.status === DISCOVER_REJECTED) {
      setVideoRejectedModalIsVisible(true);
    } else if (discover.status === DISCOVER_EXPIRED) {
      setVideoExhaustedModalIsVisible(true);
    }
    setSelectedDiscover(discover);
  };

  const handleDeleteDiscover = (discoverId: string | null) => {
    setToDeleteDiscoverId(discoverId);
    setDeleteVideoModalIsVisible(true);
  };

  const handleEditDiscover = () => {
    setUploadVideoModalIsVisible(true);
  };

  const onConfirmDelete = () => {
    if (!toDeleteDiscoverId) return;
    if (creatorDiscovers) {
      const hasExpiredOrRejectedDiscover = creatorDiscovers.some(
        (discover) => (discover.status === DISCOVER_EXPIRED || discover.status === DISCOVER_REJECTED) && discover._id !== toDeleteDiscoverId
      );
      dispatch(userHasExpiredDiscover(hasExpiredOrRejectedDiscover));
    }
    dispatch(deleteDiscoversById(toDeleteDiscoverId));
    setDeleteVideoModalIsVisible(false);
  };

  const handleConversionRate = () => {
    if (selectedDiscover?.stats?.views) {
      const result = (selectedDiscover?.stats?.clicks / selectedDiscover?.stats?.views) * 100;
      return parseFloat(result.toFixed(2));
    }
    return 0;
  };

  return (
    <Spin spinning={loadingCreatorDiscovers === "pending"} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Spin
        spinning={loading}
        style={{ maxHeight: "100dvh" }}
        indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
      >
        <Layout className="gradient-background-profile no-scroll-horizontal">
          <Layout.Content className="create-presentation-video-wrapper background-transparent">
            <HeaderTitlePage title={t("presentation-video")} fontSize="24px" />

            <UploadPresentationVideoComponent
              maxCount={5}
              defaultList={creatorDiscovers}
              onSelectDiscover={handleSelectDiscover}
              onDeleteDiscover={handleDeleteDiscover}
              setVideoBeingVerifiedModalIsVisible={setVideoBeingVerifiedModalIsVisible}
              setLoading={setLoading}
              setProgress={setProgress}
            />

            {selectedDiscover && (selectedDiscover.status === DISCOVER_APPROVED || selectedDiscover?.status === DISCOVER_PENDING) && (
              <div className="presentation-video-chart-wrapper">
                <div className="presentation-video-chart-data-container">
                  <div className="presentation-video-chart-data-item-container">
                    <Typography.Text className="presentation-video-chart-data-title">
                      {selectedDiscover?.stats?.views || 0} {t("views")}
                    </Typography.Text>
                    <Typography.Text className="presentation-video-chart-data-description">{t("generated-this-month")}</Typography.Text>
                  </div>

                  <div className="presentation-video-chart-data-item-container">
                    <Typography.Text className="presentation-video-chart-data-title">
                      {selectedDiscover?.stats?.clicks || 0} {t("clicks")}
                    </Typography.Text>
                    <Typography.Text className="presentation-video-chart-data-description">{t("generated-this-month")}</Typography.Text>
                  </div>

                  <div className="presentation-video-chart-data-item-container">
                    <Typography.Text className="presentation-video-chart-data-title">
                      {handleConversionRate()} {"%"}
                    </Typography.Text>
                    <Typography.Text className="presentation-video-chart-data-description">{t("conversion")}</Typography.Text>
                  </div>
                </div>

                {selectedDiscover?.status === DISCOVER_PENDING && (
                  <div className="presentation-video-no-chart-data-container">
                    <Typography.Text className="presentation-video-no-chart-data-title">{t("no-statistics")}</Typography.Text>
                    <Typography.Text className="presentation-video-no-chart-data-description">{t("no-statistics-descritpion")}</Typography.Text>
                  </div>
                )}
                <PresentationVideoChartComponent discover={selectedDiscover} />
              </div>
            )}

            {discoversHistory.length > 0 && <HistoryPresentationVideoComponent discoversHistory={discoversHistory} />}

            {/* <TipsVideoPresentationComponent /> */}

            <WhatKindVideoMakeModalComponent
              isOpen={whatVideoMakeModalIsVisible}
              onClose={() => {
                setWhatVideoMakeModalIsVisible(false);
              }}
              confirm={() => {}}
            />

            <VideoBeingVerifiedModalComponent
              isOpen={videoBeingVerifiedModalIsVisible}
              displayVideo={false}
              onClose={() => {
                setVideoBeingVerifiedModalIsVisible(false);
              }}
            />

            <VideoRejectedModalComponent
              isOpen={videoRejectedModalIsVisible}
              reason={selectedDiscover?.rejectedReason?.reason || ""}
              description={selectedDiscover?.rejectedReason?.description || ""}
              onClose={() => {
                setVideoRejectedModalIsVisible(false);
              }}
              deleteVideo={() => handleDeleteDiscover(selectedDiscover?._id || null)}
              editVideo={handleEditDiscover}
              rejectedFileExplanation={selectedDiscover?.rejectedReason?.file}
            />
            <VideoExhaustedModalComponent
              isOpen={videoExhaustedModalIsVisible}
              onClose={() => {
                setVideoExhaustedModalIsVisible(false);
              }}
              editVideo={handleEditDiscover}
            />

            <UploadVideoModalComponent
              isOpen={uploadVideoModalIsVisible}
              isNewVideo={true}
              onClose={() => {
                setUploadVideoModalIsVisible(false);
              }}
              showVerificationModal={() => setVideoBeingVerifiedModalIsVisible(true)}
              selectedDiscover={selectedDiscover}
              discovers={creatorDiscovers}
            />

            <DeletePresentationVideoModalComponent
              isOpen={deleteVideoModalIsVisible}
              onClose={() => {
                setDeleteVideoModalIsVisible(false);
              }}
              onConfirmDelete={onConfirmDelete}
            />
          </Layout.Content>
        </Layout>
      </Spin>
    </Spin>
  );
};

export default CreatePresentationVideoPage;
